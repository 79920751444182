import React, { useContext } from "react";
import "./Info.css";
import { Container } from "react-bootstrap";
import { HomeContext } from "../../App";
// import OlaImg from "../../../public/static/assets/images/ola.png";

const Info = () => {
  const data = useContext(HomeContext);

  const headerSectionContent = data.customization.infoSection.content;
  const headerSectionSty = data.customization.infoSection.style;

  const InfoTitleColor = {
    color: headerSectionSty.infoTitleColor,
  };
  const InfoTextColor = {
    color: headerSectionSty.infoTextColor,
  };
  const InfoBlockBg = {
    backgroundColor: headerSectionSty.infoblockBg,
  };

  // Ola

  const footerSectionContent = data.customization.footer.content;

  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }

  return (
    <>
      {olaToken && (
        <Container fluid style={InfoBlockBg} className="info_main">
          <Container className="infibg">
            <div>
              <div className="row m-0 info_row text-center">
                <div className="col-lg-1 col-md-1 col-sm-2 col-12">
                  <div className="info_number" style={InfoTitleColor}>
                    {/* <img src={`static/assets/images/ola.svg`} alt="ola-img" /> */}
                    <a
                      href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {olaimg === "" ? (
                        <img
                          src={`static/assets/images/${footerSectionContent.encrypt}`}
                          alt=""
                          className="incrypt"
                        />
                      ) : (
                        <img
                          src={olaimg}
                          alt=""
                          className="ola_logo"
                          width="50px"
                          height="50px"
                        />
                      )}
                    </a>
                  </div>
                </div>

                <div className="col-lg-11 col-md-11 col-sm-10 col-12">
                  <p className="info_para" style={InfoTextColor}>
                    {headerSectionContent.text}
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      )}
    </>
  );
};

export default Info;
