import React, { useState } from "react";
import "./PulseAnimation.css"; // Make sure to import your CSS file

const PulseAnimation = (props) => {
  const [coreColor, setCoreColor] = useState(
    props?.form_dot_bg?.backgroundColor
  );

  const { color } = props.form_do_border;

  return (
    <div>
      <svg
        className="svg-area"
        width="14px"
        height="14px"
        viewBox="0 0 400 400"
        version="1.1"
        style={{
          //   border: `1px solid  geeen"}`,
          border: `1px solid ${color}`,
          maxWidth: "100%",
          borderRadius: "50%",
        }}
      >
        <g transform="translate(200,200)">
          <circle
            id="core"
            cx="0"
            cy="0"
            r="6"
            style={{ fill: coreColor }}
          ></circle>
        </g>
      </svg>
    </div>
  );
};

export default PulseAnimation;
