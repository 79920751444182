import React, { useContext, useState } from "react";
import "./Request.css";
import { Container } from "react-bootstrap";
import { HomeContext } from "../../App";
import RequestBtn from "../../Components/RequestBtn/RequestBtn";
const Request = ({ onButtonClick }) => {
  const data = useContext(HomeContext);

  const reqSectionContent = data.customization.requestmoney.content;
  const reqSectionSty = data.customization.requestmoney.style;

  const gradColor1 = reqSectionSty.gradColor1;
  const gradColor2 = reqSectionSty.gradColor2;
  const gradColor3 = reqSectionSty.gradColor3;
  const gradColor4 = reqSectionSty.gradColor4;
  const gradColor5 = reqSectionSty.gradColor5;

  const bgColor = {
    background: reqSectionSty.backgroundColor
      ? reqSectionSty.backgroundColor
      : `linear-gradient(135deg, ${gradColor1} 0%, ${gradColor2} 27.08%, ${gradColor3} 50.00%, ${gradColor4} 76.56%, ${gradColor5} 100%)`,
  };

  const req_headding = {
    color: reqSectionSty.titleColor,
  };
  const req_para = {
    color: reqSectionSty.paraTextColor,
  };

  // const [isHover, setIsHover] = useState(false);
  // const ctaStyle = {
  //   backgroundColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  //   color: isHover
  //     ? reqSectionSty.ctaHoverTextColor
  //     : reqSectionSty.ctaTextColor,
  //   border: "1px solid",
  //   borderTopColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  //   borderLeftColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  //   borderRightColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  //   borderBottomColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  // };

  const [isHover, setIsHover] = useState(false);
  const ctaStyle = {
    backgroundColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
    color: isHover
      ? reqSectionSty.ctaHoverTextColor
      : reqSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
    borderLeftColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
    borderRightColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
    borderBottomColor: isHover ? reqSectionSty.ctaHoverBg : reqSectionSty.ctaBg,
  };

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <Container fluid className="req_blockbg" style={bgColor}>
      <div className="container">
        <div className="">
          <h1 className="req_headding" style={req_headding ? req_headding : ""}>
            {reqSectionContent.titleText ? reqSectionContent.titleText : ""}
          </h1>
          <p className="req_para" style={req_para ? req_para : ""}>
            {reqSectionContent.paraText ? reqSectionContent.paraText : ""}
          </p>

          <div className="req_main_btn">
            {/* <button className="req_btn" onClick={handleClick}>{reqSectionContent.CTAbuttonText}</button> */}
            <RequestBtn
              handleClick={handleClick}
              ctaText={reqSectionContent.CTAbuttonText}
              ctaStyle={ctaStyle}
              setIsHover={setIsHover}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Request;
