import React, { useContext, useState } from "react";
import "./CheckCriteria.css";
import { Container } from "react-bootstrap";
import { HomeContext } from "../../App";
import RequestBtn from "../../Components/RequestBtn/RequestBtn";
const CheckCriteria = ({ onButtonClick }) => {
  const data = useContext(HomeContext);
  const checkSectionContentBtn = data.customization.checkSection;
  const checkSectionContent = data.customization.checkSection.content;
  const checkSectionSty = data.customization.checkSection.style;

  const page_title = data.customization.checkSection.titleText;

  const gradColor1 = checkSectionSty.gradColor1;
  const gradColor2 = checkSectionSty.gradColor2;
  const gradColor3 = checkSectionSty.gradColor3;
  const gradColor4 = checkSectionSty.gradColor4;

  const bgColor = {
    background: checkSectionSty.backgroundColor
      ? checkSectionSty.backgroundColor
      : `linear-gradient(135deg, ${gradColor1} 0%, ${gradColor2} 39.06%, ${gradColor3} 77.60%, ${gradColor4} 100%)`,
  };

  // const bgColor = {
  //   backgroundColor: checkSectionSty.backgroundColor,
  // };

  const [isHover, setIsHover] = useState(false);
  const ctaStyle = {
    backgroundColor: isHover
      ? checkSectionSty.ctaHoverBg
      : checkSectionSty.ctaBg,
    color: isHover
      ? checkSectionSty.ctaHoverTextColor
      : checkSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover
      ? checkSectionSty.ctaHoverBg
      : checkSectionSty.ctaBg,
    borderLeftColor: isHover
      ? checkSectionSty.ctaHoverBg
      : checkSectionSty.ctaBg,
    borderRightColor: isHover
      ? checkSectionSty.ctaHoverBg
      : checkSectionSty.ctaBg,
    borderBottomColor: isHover
      ? checkSectionSty.ctaHoverBg
      : checkSectionSty.ctaBg,
  };

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <Container fluid className="check_block" style={bgColor}>
      <div className="container">
        <div className="">
          <h1 className="check_headding">{page_title}</h1>

          <div className="row check_row">
            {checkSectionContent.map((checkli, index) => (
              <div key={index} className="col-lg-4 col-md-12 col-sm-12">
                <ul className="ul_style">
                  <li className="li_style">
                    {checkli.check1 ? checkli.check1 : ""}
                  </li>
                  <li className="li_style">
                    {checkli.check2 ? checkli.check2 : ""}
                  </li>
                </ul>
              </div>
            ))}
          </div>

          <div>
            <div className="">
              <RequestBtn
                handleClick={handleClick}
                ctaText={checkSectionContentBtn.CTAbuttonText}
                ctaStyle={ctaStyle}
                setIsHover={setIsHover}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckCriteria;
