import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import { Container } from "react-bootstrap";
import { HomeContext } from "../../App";
import Allpagemodal from "../../Components/modal/allpagemodal";
import API from "../../service/Api";
const Footer = ({ webData }) => {
  const data = useContext(HomeContext);

  const footerSectionContent = data.customization.footer.content;

  const footerSectionSty = data.customization.footer.style;

  const log_para_txt = {
    color: footerSectionSty.logoParaTextColor,
  };
  const footer_link = {
    color: footerSectionSty.linkColor,
  };
  const bottom_para = {
    color: footerSectionSty.bottomParaTextColor,
  };

  const bottom_para_bg = {
    backgroundColor: footerSectionSty.footerBottomBg,
  };

  const logo = footerSectionContent.footerLogo;

  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);

  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        handleApiCall(data.WEBSITE_NAME, data.WEBSITE_ID);
      });
  }, []);

  const handleApiCall = async (web, websiteId) => {
    try {
      const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", article);
      // let res = await API.post("Content/getWebsiteContent", article);

      setApiModel(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});
    setModel({ name: "", title: x.heading, children: x.content.text });
    setFlag(true);
  };

  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }

  // hover

  const [isHoverMore1, setIsHoverMore1] = useState(false);
  const [isHoverMore2, setIsHoverMore2] = useState(false);
  const [isHoverMore3, setIsHoverMore3] = useState(false);
  const [isHoverMore4, setIsHoverMore4] = useState(false);
  const [isHoverMore5, setIsHoverMore5] = useState(false);
  const [isHoverMore6, setIsHoverMore6] = useState(false);
  const [isHoverMore7, setIsHoverMore7] = useState(false);
  const [isHoverMore8, setIsHoverMore8] = useState(false);

  const handleMouseEnterMore = (index) => {
    switch (index) {
      case 1:
        setIsHoverMore1(true);
        break;
      case 2:
        setIsHoverMore2(true);
        break;
      case 3:
        setIsHoverMore3(true);
        break;
      case 4:
        setIsHoverMore4(true);
        break;
      case 5:
        setIsHoverMore5(true);
        break;
      case 6:
        setIsHoverMore6(true);
        break;
      case 7:
        setIsHoverMore7(true);
        break;
      case 8:
        setIsHoverMore8(true);
        break;

      default:
        break;
    }
  };

  const handleMouseLeaveMore = (index) => {
    switch (index) {
      case 1:
        setIsHoverMore1(false);
        break;
      case 2:
        setIsHoverMore2(false);
        break;
      case 3:
        setIsHoverMore3(false);
        break;
      case 4:
        setIsHoverMore4(false);
        break;
      case 5:
        setIsHoverMore5(false);
        break;
      case 6:
        setIsHoverMore6(false);
        break;
      case 7:
        setIsHoverMore7(false);
        break;
      case 8:
        setIsHoverMore8(false);
        break;

      // Add more cases for other <p> tags if needed
      default:
        break;
    }
  };

  const ctaStyleMore1 = {
    color: isHoverMore1 ? footerSectionSty.linkColorHover : "#6D7484",
  };

  const ctaStyleMore2 = {
    color: isHoverMore2 ? footerSectionSty.linkColorHover : "#6D7484",
  };
  const ctaStyleMore3 = {
    color: isHoverMore3 ? footerSectionSty.linkColorHover : "#6D7484",
  };
  const ctaStyleMore4 = {
    color: isHoverMore4 ? footerSectionSty.linkColorHover : "#6D7484",
  };
  const ctaStyleMore5 = {
    color: isHoverMore5 ? footerSectionSty.linkColorHover : "#6D7484",
  };
  const ctaStyleMore6 = {
    color: isHoverMore6 ? footerSectionSty.linkColorHover : "#6D7484",
  };
  const ctaStyleMore7 = {
    color: isHoverMore7 ? footerSectionSty.linkColorHover : "#6D7484",
  };

  const ctaStyleMore8 = {
    color: isHoverMore8 ? footerSectionSty.linkColorHover : "#6D7484",
  };

  return (
    <>
      <Container fluid className="footer_block">
        <div className="container">
          <div className="footer_top">
            <div className="row">
              <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12">
                <div className="footer_logo">
                  <img src={`static/assets/images/${logo}`} alt="footerlogo" />
                </div>
                <p className="footer_logopara" style={log_para_txt}>
                  {footerSectionContent.logoParaText}
                </p>
              </div>

              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 col-6">
                <div className="footer_link_main">
                  <h3 className="head_tag">Company</h3>
                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore1,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(1)}
                    onMouseLeave={() => handleMouseLeaveMore(1)}
                    onClick={() => setShow("contact")}
                  >
                    <>Contact us</>
                  </p>
                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore2,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(2)}
                    onMouseLeave={() => handleMouseLeaveMore(2)}
                    onClick={() => setShow("terms")}
                  >
                    <>Terms of service</>
                  </p>

                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore3,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(3)}
                    onMouseLeave={() => handleMouseLeaveMore(3)}
                    onClick={() => setShow("policy")}
                  >
                    <>Privacy Policy</>
                  </p>

                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore4,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(4)}
                    onMouseLeave={() => handleMouseLeaveMore(4)}
                    onClick={() => setShow("e_consent")}
                  >
                    <>E-consent</>
                  </p>

                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore5,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(5)}
                    onMouseLeave={() => handleMouseLeaveMore(5)}
                    onClick={() => setShow("e_consent")}
                  ></p>
                </div>
              </div>

              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 col-6">
                <div className="footer_link_main">
                  <h3 className="head_tag">Important links</h3>
                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore6,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(6)}
                    onMouseLeave={() => handleMouseLeaveMore(6)}
                    onClick={() => setShow("disclaimer")}
                  >
                    <>Disclaimer</>
                  </p>

                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore7,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(7)}
                    onMouseLeave={() => handleMouseLeaveMore(7)}
                    onClick={() => setShow("ccpa")}
                  >
                    <>Do not sell my information</>
                  </p>
                  <p
                    style={{
                      ...footer_link,
                      ...ctaStyleMore8,
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnterMore(8)}
                    onMouseLeave={() => handleMouseLeaveMore(8)}
                    onClick={() => setShow("unsubscribe")}
                  >
                    <>Unsubscribe</>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer_img_main">
              <a
                href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                rel="noreferrer"
                target="_blank"
              >
                {olaimg === "" ? (
                  <img
                    src={`static/assets/images/${footerSectionContent.encrypt}`}
                    alt=""
                    className="incrypt"
                  />
                ) : (
                  <img
                    src={olaimg}
                    alt=""
                    className="ola_logo"
                    width="40px"
                    height="40px"
                  />
                )}
              </a>
              <img
                src={`static/assets/images/${footerSectionContent.sslLogo}`}
                alt="ssllogo"
              />
              <img
                src={`static/assets/images/${footerSectionContent.safe}`}
                alt="safelogo"
              />
            </div>

            <p className="copy_right_txt">@ All right reserved.</p>
          </div>
        </div>
      </Container>
      <Container fluid style={bottom_para_bg} className="bottom_para_sty">
        <Container>
          <p className="footer_para" style={bottom_para}>
            {footerSectionContent.bottomParaText}
          </p>
        </Container>
      </Container>
      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
};

export default Footer;
