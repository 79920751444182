import React, { useContext, useEffect, useState } from "react";
import "./BannerForm.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { HomeContext } from "../../App";
import useQuery from "../../utils/useQuery";
import { createSearchParams, useNavigate } from "react-router-dom";
import API from "../../service/Api";
import Allpagemodal from "../../Components/modal/allpagemodal";
import PulseAnimation from "./PulseAnimation/PulseAnimation";

const BannerForm = (props) => {
  const { GaTrack } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isHover, setIsHover] = useState(false);
  const [focusedField, setFocusedField] = useState(false);
  const [fieldIsHover, setFieldIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const data = useContext(HomeContext);

  const bannerSectionContent = data.customization.bannerSection.content;
  const bannerSectionSty = data.customization.bannerSection.style;

  const ctaparaColor = {
    color: bannerSectionSty.termsTextColor,
  };
  const ctalinkColor = {
    color: bannerSectionSty.themeColor2,
  };

  const handleFieldMouseEnter = (field) => {
    // alert("Test");

    setFieldIsHover(field);
  };
  const handleFieldMouseLeave = (field) => {
    setFieldIsHover(null);
    // setFocusedField(false);
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };
  const handleBlur = () => {
    setFocusedField(false);
  };

  const inputBg = {
    backgroundColor: bannerSectionSty.fieldBg,
  };

  const form_Header_Bg = {
    backgroundColor: bannerSectionSty.formHeaderBg,
  };

  const form_dot_bg = {
    backgroundColor: bannerSectionSty.formDotBg,
  };
  const form_do_border = {
    color: bannerSectionSty.formDotBg,
  };

  const form_header_text = {
    color: bannerSectionSty.formHeaderTextColor,
  };
  const disc_link_color = {
    color: bannerSectionSty.discLinkColor,
  };

  const input_focus = {
    color:
      bannerSectionSty && bannerSectionSty.inputfocuscolor
        ? bannerSectionSty.inputfocuscolor === undefined
          ? bannerSectionSty.fieldHoverBorder
          : bannerSectionSty.inputfocuscolor
        : bannerSectionSty.fieldHoverBorder,
  };

  const inputFocus = {
    // borderColor: bannerSectionSty.fieldHoverBorder,
    // margin: "-2px",
    border: "1px solid",
    WebkitBoxShadow: focusedField
      ? `2px 2px 2px 0px ${input_focus?.color}, -2px -2px 2px 0px ${input_focus?.color}, -2px 2px 2px 0px ${input_focus?.color}, 2px -2px 2px 0px ${input_focus?.color}`
      : "none",
    borderColor:
      fieldIsHover || focusedField
        ? bannerSectionSty.fieldHoverBorder
        : bannerSectionSty.fieldBorderColor,
  };

  const ctaStyle = {
    backgroundColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    color: isHover
      ? bannerSectionSty.ctaHoverTextColor
      : bannerSectionSty.ctaTextColor,
    border: "0px solid",
    borderTopColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderLeftColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderRightColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
    borderBottomColor: isHover
      ? bannerSectionSty.ctaHoverBg
      : bannerSectionSty.ctaBg,
  };

  const maxAmpunt = data.max_amount;

  // pass query params

  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const max_amount = query.get("max_amount") || "";
  const email = query.get("email") || "";

  const onSubmit = (data) => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      max_amount,
    };
    // setformData(myData);

    const params = {
      email: myData.email || "",
      firstName: myData.firstName || "",
      lastName: myData.lastName || "",
      s1: myData.utm_campaign || "",
      s2: myData.s2 || "",
      s3: myData.s3 || "",
      uid: myData.uid || "",
      utm_medium: myData.utm_medium || "",
      amount: myData.amount || "",
      max_amount: myData.max_amount || "",
    };

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });

    GaTrack("First Form Submit", "First Form Submit");
  };

  // Modal
  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);
  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        handleApiCall(data.WEBSITE_NAME, data.WEBSITE_ID);
      });
  }, []);

  const handleApiCall = async (web, websiteId) => {
    try {
      const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", article);

      setApiModel(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});

    setModel({ name: "", title: x.heading, children: x.content.text });
    setFlag(true);
  };

  // Animation Dot fun
  const [fillColor, setFillColor] = useState("#ff4f81"); // Set your initial fill color here

  const handleColorChange = () => {
    // Update fill color dynamically, you can replace this logic with your own color change logic
    const newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    setFillColor("green");
  };

  // animation css

  return (
    <>
      <div className="innercontainer">
        <form onSubmit={handleSubmit(onSubmit)} className="bannerForm">
          <div className="form_header" style={form_Header_Bg}>
            <div className="bottm_par_main">
              {/* <p className="span_dot"></p> */}
              <p className="banner_bottompara">
                <PulseAnimation
                  form_dot_bg={form_dot_bg}
                  form_do_border={form_do_border}
                />
              </p>
              <div className="bannet_top_bg px-2" style={form_header_text}>
                {bannerSectionContent.ctaBottomText1}
              </div>
            </div>
          </div>
          <p className="from_headding">{bannerSectionContent.formHedding}</p>
          <div className="field">
            <div className="form_field">
              <label>{bannerSectionContent.label1}</label>
              <div className="input_main">
                <input
                  type="tel"
                  className="amount_but inline-edit"
                  placeholder="$ 500"
                  name="amount"
                  id="amount"
                  onFocus={() => handleFocus("amount")}
                  onBlur={handleBlur}
                  style={
                    focusedField === "amount"
                      ? inputFocus
                      : fieldIsHover === "amount"
                      ? inputFocus
                      : inputBg
                  }
                  onMouseEnter={() => handleFieldMouseEnter("amount")}
                  onMouseLeave={handleFieldMouseLeave}
                  {...register("amount", {
                    required: "This input is required.",
                    pattern: {
                      value: /\b(0|[1-9]\d*)?([05]0)\b/,
                      message: "This value should be a multiple of 50.",
                    },
                    min: {
                      value: 100,
                      message: `This value should be between 100 and ${maxAmpunt}.`,
                    },
                    max: {
                      value: maxAmpunt,
                      message: `This value should be between 100 and ${maxAmpunt}.`,
                    },
                  })}
                />
                {/* <img
                  src={`static/assets/images/money.svg`}
                  alt="icon-money"
                  className="img-fluid"
                /> */}

                <div style={{ position: "absolute", left: "16px" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="money-round-1180-svgrepo-com 1"
                      clip-path="url(#clip0_746_5149)"
                    >
                      <g id="Page-1">
                        <g id="Dribbble-Light-Preview">
                          <g id="icons">
                            <g id="Group 2567">
                              <rect
                                id="Rectangle 2267"
                                x="0.9"
                                y="4.50001"
                                width="22.2"
                                height="15"
                                rx="3.9"
                                // stroke="#929AAD"
                                stroke={`${
                                  focusedField === "amount"
                                    ? inputFocus.borderColor
                                    : fieldIsHover === "amount"
                                    ? inputFocus.borderColor
                                    : "#929AAD"
                                }`}
                                inputFocus
                                stroke-width="1.8"
                              />
                              <rect
                                id="Rectangle 2268"
                                x="9.29941"
                                y="9.30001"
                                width="5.4"
                                height="5.4"
                                rx="2.7"
                                stroke={`${
                                  focusedField === "amount"
                                    ? inputFocus.borderColor
                                    : fieldIsHover === "amount"
                                    ? inputFocus.borderColor
                                    : "#929AAD"
                                }`}
                                stroke-width="1.8"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_746_5149">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <ErrorMessage
              errors={errors}
              name="amount"
              render={({ message }) => <p className="error_color">{message}</p>}
            />
          </div>

          <div>
            <div className="form_field">
              <label>{bannerSectionContent.label2}</label>
              <div className="input_main">
                <input
                  type="email"
                  className="amount_but inline-edit"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  onFocus={() => handleFocus("email")}
                  onBlur={handleBlur}
                  style={
                    focusedField === "email"
                      ? inputFocus
                      : fieldIsHover === "email"
                      ? inputFocus
                      : inputBg
                  }
                  onMouseEnter={() => handleFieldMouseEnter("email")}
                  onMouseLeave={handleFieldMouseLeave}
                  {...register("email", {
                    required: "Email Address is required",

                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  value={email !== "" ? email : undefined}
                />
                {/* <img
                  src={`static/assets/images/email.svg`}
                  alt="icon-email"
                  className="img-fluid"
                /> */}
                <div style={{ position: "absolute", left: "16px" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="email-svgrepo-com 2" clip-path="url(#clip0_339_168)">
                      <g id="Group 2568">
                        <rect
                          id="Rectangle 2267"
                          x="0.9"
                          y="3.3"
                          width="22.2"
                          height="17.4"
                          rx="3.9"
                          stroke={`${
                            focusedField === "email"
                              ? inputFocus.borderColor
                              : fieldIsHover === "email"
                              ? inputFocus.borderColor
                              : "#929AAD"
                          }`}
                          stroke-width="1.8"
                        />
                        <path
                          id="Vector 53"
                          d="M1.7998 5.39999L9.08404 10.9703C10.8051 12.2864 13.1945 12.2864 14.9156 10.9703L22.1998 5.39999"
                          stroke={`${
                            focusedField === "email"
                              ? inputFocus.borderColor
                              : fieldIsHover === "email"
                              ? inputFocus.borderColor
                              : "#929AAD"
                          }`}
                          stroke-width="1.8"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_339_168">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <p className="error_color">{message}</p>}
            />
          </div>
          <p className="banner_para" style={ctaparaColor}>
            By clicking 'Request now' ,you agree to our{" "}
            <span style={disc_link_color} onClick={() => setShow("policy")}>
              Privacy Policy
            </span>
            ,{" "}
            <span style={disc_link_color} onClick={() => setShow("terms")}>
              Terms
            </span>
            ,{" "}
            <span style={disc_link_color} onClick={() => setShow("e_consent")}>
              E-Consent
            </span>
            ,{" "}
            <span style={disc_link_color} onClick={() => setShow("rates")}>
              Rate & Fees
            </span>{" "}
            and receive special offers from us and our marketing partners via
            email communication.
          </p>
          <button
            type="submit"
            className="requst_btn mytoast"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={ctaStyle}
          >
            {bannerSectionContent.ctaText}
          </button>
        </form>
      </div>
      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
};

export default BannerForm;
