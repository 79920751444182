
const RequestBtn = (props) => {
  const { handleClick, ctaText, ctaStyle, setIsHover } = props;
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <div className="req_main">
        <button
          style={ctaStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="req_btn"
          onClick={handleClick}
        >
          {ctaText}
        </button>
      </div>
    </>
  );
};

export default RequestBtn;
