import React, { useState } from "react";
// import { ChevronDown } from "react-bootstrap-icons";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

function CustomBootstrapAccordion({ defaultExpanded, title, icon, children }) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion defaultActiveKey={isExpanded ? "0" : ""}>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleToggle}>
          <span>{title}</span>
          {icon}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ backgroundColor: "#e0e0e05e" }}>
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default CustomBootstrapAccordion;
