import React, { useContext, useState } from "react";
import "./WhyUs.css";
import { Container } from "react-bootstrap";
import { HomeContext } from "../../App";
import RequestBtn from "../../Components/RequestBtn/RequestBtn";
const WhyUs = ({ onButtonClick }) => {
  const data = useContext(HomeContext);

  const whyUsSectionContent = data.customization.whyUsSection.content;
  const whyUsSectionSty = data.customization.whyUsSection.style;
  const whyUsSectionIMG = data.section1_img;

  var splitag = whyUsSectionIMG.split(",");
  var whyImg1 = splitag[0];
  var whyImg2 = splitag[1];
  var whyImg3 = splitag[2];

  const titleTxt = {
    color: whyUsSectionSty.titleColor,
  };

  const gradColor1 = whyUsSectionSty.gradColor1;
  const gradColor2 = whyUsSectionSty.gradColor2;
  const gradColor3 = whyUsSectionSty.gradColor3;
  const gradColor4 = whyUsSectionSty.gradColor4;

  const bgColor = {
    background: whyUsSectionSty.backgroundColor
      ? whyUsSectionSty.backgroundColor
      : `linear-gradient(135deg, ${gradColor1} 0%, ${gradColor2} 39.06%, ${gradColor3} 77.60%, ${gradColor4} 100%)`,
  };

  const boldtxtColor = {
    color: whyUsSectionSty.boldTextColor,
  };
  const paratxtColor = {
    color: whyUsSectionSty.paraTextColor,
  };

  const [isHover, setIsHover] = useState(false);
  const ctaStyle = {
    backgroundColor: isHover
      ? whyUsSectionSty.ctaHoverBg
      : whyUsSectionSty.ctaBg,
    color: isHover
      ? whyUsSectionSty.ctaHoverTextColor
      : whyUsSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover
      ? whyUsSectionSty.ctaHoverBg
      : whyUsSectionSty.ctaBg,
    borderLeftColor: isHover
      ? whyUsSectionSty.ctaHoverBg
      : whyUsSectionSty.ctaBg,
    borderRightColor: isHover
      ? whyUsSectionSty.ctaHoverBg
      : whyUsSectionSty.ctaBg,
    borderBottomColor: isHover
      ? whyUsSectionSty.ctaHoverBg
      : whyUsSectionSty.ctaBg,
  };

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <Container fluid className="why_blockbg" style={bgColor}>
      <div className="container">
        <div className="">
          <h1 className="whyus_hadding" style={titleTxt}>
            {whyUsSectionContent.titleText ? whyUsSectionContent.titleText : ""}{" "}
          </h1>
          <div className="row row_sty mob_sty">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main">
                <div className="box_img mob_box_img">
                  <img src={`static/assets/images/${whyImg1}`} alt="whysimg" />
                </div>
                <h1 className="box_headding" style={boldtxtColor}>
                  {" "}
                  {whyUsSectionContent.icon1bold
                    ? whyUsSectionContent.icon1bold
                    : ""}
                </h1>
                <p className="box_par" style={paratxtColor}>
                  {whyUsSectionContent.icon1text
                    ? whyUsSectionContent.icon1text
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main mob_sty">
                <div className="box_img mob_box_img">
                  <img src={`static/assets/images/${whyImg2}`} alt="whysimg" />
                </div>
                <h1 className="box_headding" style={boldtxtColor}>
                  {whyUsSectionContent.icon2bold
                    ? whyUsSectionContent.icon2bold
                    : ""}
                </h1>
                <p className="box_par" style={paratxtColor}>
                  {whyUsSectionContent.icon2text
                    ? whyUsSectionContent.icon2text
                    : ""}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="box_main mob_sty">
                <div className="box_img mob_box_img">
                  <img src={`static/assets/images/${whyImg3}`} alt="whysimg" />
                </div>
                <h1 className="box_headding" style={boldtxtColor}>
                  {whyUsSectionContent.icon3bold
                    ? whyUsSectionContent.icon3bold
                    : ""}
                </h1>
                <p className="box_par" style={paratxtColor}>
                  {whyUsSectionContent.icon3text
                    ? whyUsSectionContent.icon3text
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <RequestBtn
              handleClick={handleClick}
              ctaText={whyUsSectionContent.CTAbuttonText}
              ctaStyle={ctaStyle}
              setIsHover={setIsHover}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyUs;
